// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import algosdk from 'algosdk'
import { PeraWalletConnect } from '@perawallet/connect'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import WebbIcon from "../webx/webb-icon";


import { GetLocalUser } from "../../services/srvc-user-local";
import { TransferDetailsRand, TransferDataSetRand } from "../../services/srvc-transfers-rand-realm";

const admin = 'PRIWTAAQUD7XCE2OPYSYCK6YJNHLLJH22JRDK246V3GGG3L3U2VLX62XVA'
const feeaccount = '4EFZKQZMC7MK6BS5HXH2KJ2UTH5QAGQDCETEHQJQAFWUODPLF7KAURCQFM'


const listStatus = [
  { name: 'draft', back:'back-color-lite', text:'text-color-tone' },
  { name: 'in process', back:'back-color-warning', text:'text-color-dark' },
  { name: 'scheduled', back:'back-color-warning', text:'text-color-dark' },
  { name: 'overdue', back:'back-color-warning', text:'text-color-dark' },
  { name: 'cancelled', back:'back-color-danger', text:'text-color-wite' },
  { name: 'failed', back:'back-color-warning', text:'text-color-dark' },
  { name: 'success', back:'back-color-success', text:'text-color-wite' },
]

const brand = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNMcstvDKUbEOF3ATaINPOjbXkA-tGkoYd7djlqW7VO53gYdt_e_gQ4-G_-deVTrY1L2Y&usqp=CAU'
const artwork = 'https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2019/01/digital-illustration-guide.jpg?w=960&h=600&crop=1'

export default function TransferRandViewModule () {

  const asset = {form: 'ww'}
  const navigate = useNavigate();
  const {chain, id} = useParams();
  
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [formerror, setFormError] = useState('');

  const [item, setItem] = useState(); // transaction info
  const [transfer, setTransfer] = useState(); // *** not used

  const [account, setAccount] = useState();
  const [accountstatus, setAccountStatus] = useState(false);
  const [balance, setBalance] = useState();
  const [balancestatus, setBalanceStatus] = useState(false);
  const [network, setNetwork] = useState();
  const [networkstatus, setNetworkStatus] = useState(false);

  const [session, setSession] = useState({})
  const [state, setState] = useState({})

  const isConnectedToPeraWallet = !!account
  const peraWallet = new PeraWalletConnect({ chainId: 'testnet' })

  const [data, setData] = useState({
    name: '',
    mail: 'user@route.bz',
    location: 'India'
  })


  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoading(true);

        const result = await TransferDetailsRand({data: {item: id, chain: chain}})
        console.log (result.data)
        setItem({...result.data, code: result.code, memo: result.memo})

        if (result.data) {
        
          setData({
            ...data, 
            mail: result.data.debit.mail, name: result.data.debit.name
          })
        }

        await PeraWalletInit ()
        setLoading(false);
      }
      fetchData()
    } else {}
  },[id]);

  const PeraWalletInit = async () => {

    try {
        // Reconnect to the session when the component is mounted
        const accounts = await peraWallet.reconnectSession()
        
        // Setup the disconnect event listener
        console.log('accounts', accounts)
        console.log('pw', peraWallet)

        peraWallet?.connector?.on('disconnect', handleDisconnect)
        if (accounts.length) {
          setNetworkStatus(true)
          setNetwork('416002')

          setAccount(accounts[0])
          setSession(peraWallet)
          await AccountBalanceSet('testnet', accounts[0])

          setAccountStatus(true)

        }
    } catch (error) {
      console.log(error.message)
    }

  }


  const AccountBalanceSet = async (chain, address) => {
    const client = new algosdk.Algodv2(
      '',
      `https://${chain}-api.algonode.cloud`,
      ''
    )

    const accountInfo = await client
      .accountInformation(address)
      .setIntDecoding(algosdk.IntDecoding.BIGINT)
      .do()

    console.log(accountInfo)
    if (accountInfo) {
      setBalance(parseInt(accountInfo?.amount)/1000000 || 0)
    
    }
    
    if (parseInt(accountInfo?.amount) > item?.sale?.amnt) setBalanceStatus(true)

  }


  const handleConnect = async () => {
    try {
      const newAccounts = await peraWallet.connect()
      console.log('new', newAccounts)

      peraWallet.connector?.on('disconnect', handleDisconnect)
      
      setNetworkStatus(true)
      setNetwork('416002')

      setAccount(newAccounts[0])
      setAccountStatus(true)

    } catch (error) {
      console.log(error)
    }
  }

  const handleDisconnect = () => {
    peraWallet.disconnect()
    setAccount(null)

    setNetworkStatus(false)
    setAccountStatus(false)
    setBalanceStatus(false)
  }

  const TransferAuthorize = async () => {

    console.log(+(item.sale.amnt))

    const { connector, chainId } = session
    if (!connector) {
      return
    }
    const suggestedParams = await new algosdk.Algodv2(
      '',
      `https://${chainId}-api.algonode.cloud`,
      ''
    )
      .getTransactionParams()
      .do()
    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      from: account,
      to: admin,
      amount: +(item.sale.amnt),
      note: new Uint8Array(Buffer.from(item && item.meta && item.meta.memo || '******')),
      suggestedParams
    })
    const txnsToSign = {
      txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString(
        'base64'
      ),
      message: `Transfer Amount ${item.sale.amnt/1000000} for ${item.meta.memo} to ${item.credit.name}`
    }

    // call next action
    const result = await TransferProcess([txnsToSign])
    return result

  }

  const TransferProcess = async txnsToSign => {
    try {
      const { connector, chainId } = session
      setState({
        ...state,
        pendingRequest: true,
        msg: 'Pending Request! Approve or reject request using your wallet'
      })
      const walletTxns = txnsToSign.map(
        ({ txn, signers, authAddr, message }) => ({
          txn,
          signers, // TODO: put auth addr in signers array
          authAddr,
          message
        })
      )
      // sign transaction
      const requestParams = [walletTxns]
      const request = formatJsonRpcRequest('algo_signTxn', requestParams)
      const result = await connector.sendCustomRequest(request)
      console.log('Raw response:', result)
      setState({ ...state, pendingRequest: true, msg: 'Call Request Approved' })
      const decodedResult = result.map(element =>
        element ? new Uint8Array(Buffer.from(element, 'base64')) : null
      )
      const signedTxnInfo = algosdk.decodeSignedTransaction(decodedResult[0])
      const txID = signedTxnInfo.txn.txID()
      const signedTxns = decodedResult.map(item => item)
      setState({
        ...state,
        result,
        signedTxns,
        pendingRequest: true,
        msg: 'Submitting on network...'
      })

      return await TransferNetworkSubmit(signedTxns, chainId)
      
      
    } catch (error) {
      console.log(error.message)
      setState({ ...state, pendingRequest: false, label: `${error.message}` })
    }
  }

  const TransferNetworkSubmit = async (signedTxns, chain) => {
    const client = new algosdk.Algodv2(
      '',
      `https://${chain}-api.algonode.cloud`,
      ''
    )
    const { txId } = await client.sendRawTransaction(signedTxns[0]).do()
    console.log (txId)

    let lastStatus = await client.status().do()
    console.log (lastStatus)

    let lastRound = lastStatus['last-round']
   
    while (true) {
      const status = await client.pendingTransactionInformation(txId).do()
      console.log (status)

      if (status['pool-error']) {
        throw new Error(`Transaction Pool Error: ${status['pool-error']}`)
      }
      if (status['confirmed-round']) {
        return status['confirmed-round']
      }
      lastStatus = await client.statusAfterBlock(lastRound + 1).do()
      lastRound = lastStatus['last-round']
    }
  }


  const handleTransfer = async() => {
    console.log ('transfer')
    setLoading(true)
    setSubmit(true)
    
    // authorize on pera wallet and complete
    const trxn = TransferAuthorize()
    if (trxn) {
      console.log(trxn)
    }

    // const result = await TransferDataSetRand({data: {
    //   item: id, chain: '416002', status: 6,
    //   hash: 'trxn.hash', debit: {account: account},
    //   feex: {amount: '001', ticker: item.sale.tick }
    // }})
    // console.log (result)
    
    // setLoading(false)
    // if (result.data) {
    //   window.open(item.link.done, '_self')
    // }

    setLoading(false)    
  }

  const handleSubmit= async() => {
    console.log ('transfer')
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }


  if (loading && !submit) return <>
      <div className="text-center">
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbIcon data={{color: 'text-color-tone', size: 'text-icon-md'}}/>
        <WebbDividerSmall />
        <p className={''}>{'Please Wait...'}</p>
      </div>
  </>

  if (!loading && item && [404, 406, 409, 410].includes(item.code)) return <>
    <div className="text-center">
      <WebbDividerMedium />
      <WebbDividerMedium />
      <WebbIcon data={{color: 'text-color-tone', size: 'text-icon-md'}}/>
      <WebbDividerSmall />
      <p className={item.code == 404 ? '' : 'd-none'}>{'Transaction Not Found'}</p>
      <p className={item.code == 410 ? '' : 'd-none'}>{'Transaction Cancelled'}</p>
      <p className={item.code == 406 ? '' : 'd-none'}>{'Transaction Timeout'}</p>
      <p className={item.code == 409 ? '' : 'd-none'}>{'Transaction Closed'}</p>
    </div>

    {/* mobile footer */}
    <div className="back-color-wite text-center px-3 fixed-bottom" >
      <WebbDividerSmall/>
      <p className="text-small text-color-tone mx-3 mb-2">
        Powered by <span className="text-normal text-bold">route</span>
      </p>
      <p className="text-small text-color-tone mx-3">
        Terms 
        <span className="mx-1"></span>
        Privacy
      </p>
      <WebbDividerSmall/>
    </div>    
  </>

  return (
  <>
    
    <div className="container-fluid">

    
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <WebbDividerMedium />
        <WebbDividerSmall />
        <div className="mx-3">
          <span className="rounded-xx ">
            <img src={brand} className="rounded-xx p-1 border shadow-sm" width={33} alt='route brand'></img>
          </span>
          <span className="ms-2 mt-1 mb-1 text-uppercase">{item && item.credit.name || '******'}</span>
        </div>

        
        <WebbDividerMedium />
        <div className="mx-3">
          <p className="text-small text-color-tone m-0 mb-2">{'Amount to Pay'}</p>
          <p className="m-0 mb-3">
            <span className="caption-md me-1" style={{lineHeight:'2rem'}}>{((item && item.sale.amnt)/1000000).toFixed(6) || '******'}</span>
            <span className="text-uppercase align-top">{(item && item.sale.tick) || '******'}</span>
          </p>
          <p className="text-normal m-0">{item && item.meta.memo || '******'}</p>
        </div>

        <WebbDividerSmall />
        <div className="media-cube d-none">
          <img src={artwork} className="rounded-wd p-1 shadow-sm" alt='route asset'></img>
          
        </div>

        <WebbDividerMedium />
        <WebbDividerMedium />

        <div className="text-small text-color-tone d-none d-md-block mx-3" 
          style={{position:'fixed',bottom:'3rem'}}>
          Powered by <span className="text-normal text-bold">route</span> <span className="mx-3"></span>
          Terms <span className="mx-1"></span>
          Privacy
        </div>

      </div>

      <div className="col-md-5 back-color-lite" style={{minHeight:'100vh'}}>
        
        <WebbDividerMedium />
        <WebbDividerSmall />
        <p className="mx-3 text-lead">Payment Details</p>

        <WebbDividerSmall />
        <div className="mx-3">
          <div className="mb-3">  
            <label className="form-label small">Email <FormNeeded/></label>
            <input type="text" className="form-control height-md  "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.mail}
              onChange={({ target }) => {handleChange("mail", target.value); }}
              disabled={true}
              placeholder="user@route.bz">
            </input>
          </div>
        
          <div className="mb-3">  
            <label className="form-label small">Name <FormNeeded/></label>
            <input type="text" className="form-control height-md  "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value); }}
              disabled={true}
              placeholder="">
            </input>
          </div>        
        
          <div className="mb-3 d-none">  
            <label className="form-label small">Country or region <FormNeeded/></label>
            <input type="text" className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.location}
              onChange={({ target }) => {handleChange("location", target.value); }}
              disabled={loading || submit}
              placeholder="">
            </input>
          </div>


        </div>

        {/* pera integration */}
        <WebbDividerMedium />

        <div className={`p-3 mx-2 rounded-wd border ${item && item.stat!==6? 'mx-3 back-color-wite': 'd-none'}`}>
          <p className="">
            <span><i className={`text-small bx ${networkstatus ? 'bxs-check-circle text-color-success' : 'bxs-error-circle text-color-danger'}`}></i></span>
            <span className="ms-1 text-bold">Network: </span>
            <span className="ms-1">{networkstatus ? network : '******'}</span>
          </p>

          <p className="text-sm">
            <span><i className={`text-small bx ${networkstatus ? 'bxs-check-circle text-color-success' : 'bxs-error-circle text-color-danger'}`}></i></span>
            <span className="ms-1 text-bold">Account: </span>
            <span className="ms-1 text-sm">{networkstatus ? account : '******'}</span>
          </p>
          <p className="text-sm m-0">
            <span><i className={`text-small bx ${networkstatus && balancestatus ? 'bxs-check-circle text-color-success' : 'bxs-error-circle text-color-danger'}`}></i></span>
            <span className="ms-1 text-bold">Balance: </span>
            <span className="ms-1">{networkstatus ? parseFloat(balance).toFixed(6) : '******'}</span>
            
          </p>
        </div>


        <div className={`mx-3 ${!loading && !accountstatus ? '': 'd-none'}`}>
          <WebbDividerSmall />
          <button className="btn btn-success back-color-warning text-color-dark border-none rounded-wd text-normal w-100"
              onClick={()=> handleConnect()}
              disabled={loading}
            >
              <div className="d-flex">
                <div className=""><i className="bx bx-globe text-icon-sm"></i></div>
                <div className="ms-2 mt-1">Connect Pera Wallet</div>
                <div className="ms-auto" >
                  <i className="bx bx-chevron-right text-icon-sm"></i>
                </div>
              </div>
          </button>

        </div>

        <div className={`mx-3 ${!loading && accountstatus ? '': 'd-none'}`}>
          <WebbDividerSmall />
          <button className="btn btn-success back-color-warning text-color-dark border-none rounded-wd text-normal w-100"
            onClick={()=> handleTransfer()}
            disabled={loading}
          >
            <div className="d-flex">
              <div className=""><i className="bx bx-globe text-icon-sm"></i></div>
              <div className="ms-2 mt-1">Authorize Transfer</div>
              <div className="ms-auto" >
                <i className="bx bx-chevron-right text-icon-sm"></i>
              </div>
            </div>
          </button>

        </div>  

        <div className={`mx-3 ${!loading && !accountstatus ? 'd-none': ''}`}>
          <WebbDividerSmall />
          <button className="btn btn-danger back-color-wite text-color-tone border-none rounded-wd text-normal w-100"
              onClick={()=> handleDisconnect() }
              disabled={loading}
            >
              <div className="d-flex">
                <div className=""><i className="bx bx-minus-circle text-icon-sm"></i></div>
                <div className="ms-2 mt-1">Disconnect Pera Wallet</div>
                <div className="ms-auto" >
                  <i className="bx bx-chevron-right text-icon-sm"></i>
                </div>
              </div>
          </button>

        </div>      


        {/* loader */}
        <div className={`mx-3 ${loading ? '': 'd-none'}`}>
          <p>Please Wait...</p>
        </div>

      </div>
      <div className="col-md-1 back-color-lite d-none d-md-block" style={{minHeight:'100vh'}}></div>

    </div>

    </div>


    {/* mobile footer */}
    <div className="back-color-lite d-md-none px-3">

      <div className="text-small text-color-tone text-start mx-3" 
        >
        Powered by <span className="text-normal text-bold">route</span> <span className="mx-3"></span>
        Terms <span className="mx-1"></span>
        Privacy
      </div>

      <WebbDividerMedium/>
    </div>
        
  </>

  )
}