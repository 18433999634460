// 
import axios from "axios";
import { saveAs } from 'file-saver';

const base = 'https://api.nft.storage'
const scrt = process.env.REACT_APP_NFT_STORE_APIK
const head = {
  Authorization: `Bearer ${scrt}`
}


export const MediaSave = async (item) => {

  const basx = `${base}/upload`
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return {
      cidx: result.data.value.pin.cid,
      link: `https://nftstorage.link/ipfs/${result.data.value.pin.cid}`,
      size: result.data.value.pin.size,
      status: result.data.value.pin.status,
      created: Date.now(result.data.value.pin.created),
      service: result.data.value.scope,
    }
  } catch (error) {
    console.log (error);
    return error
  }

}

export const MediaLinkConvert = async (item) => {
  const imageOriginUrl = item.media.link
  
  const r = await fetch(imageOriginUrl)
  if (!r.ok) {
    throw new Error(`error fetching image: [${r.statusCode}]: ${r.status}`)
  }
  // return r.blob()

  const result = await MediaSave(r.blob())
  console.log(result)
  return result

}


export const MetadataLinkCreate = async (item) => {

  const tokendata = {
    "name": item.name,
    "description": item.memo,
    "image": item.media.link,
    "properties" : {},
    "attributes": []
  }
  console.log ('meta: ', tokendata)

  var blob = new Blob([JSON.stringify(tokendata)], {type: "application/json;charset=utf-8"});
  // saveAs(blob, `route-${Date.now()}.json`);

  const result = await MediaSave(blob)
  console.log(result)
  return result
}



export const MediaLinkCreate = async (item) => {

  // file comes in base64 
  const filedata = ""
  const mime = ""
  const byteCharacters = atob(filedata);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  var blob = new Blob([byteArray], {type: "image/png;"});
  //saveAs(blob, `wise-${data.wise}.png`);  

  const result = await MediaSave(blob)
  console.log(result)
  return result
}