import { useState, useEffect } from 'react'

import algosdk from 'algosdk'
import { PeraWalletConnect } from '@perawallet/connect'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'

import WebbLoader from '../webx/webb-loader'

const admin = 'PRIWTAAQUD7XCE2OPYSYCK6YJNHLLJH22JRDK246V3GGG3L3U2VLX62XVA'

export default function AlgoWallet () {

  const [accountAddress, setAccountAddress] = useState('')
  const [session, setSession] = useState({})
  const [state, setState] = useState({})

  const isConnectedToPeraWallet = !!accountAddress
  const peraWallet = new PeraWalletConnect({
    chainId: 'testnet'
  })

  const handleDisconnect = () => {
    peraWallet.disconnect()
    setAccountAddress(null)
  }
  
  const handleConnect = async () => {
    try {
      const newAccounts = await peraWallet.connect()
      console.log('new', newAccounts)
      peraWallet.connector?.on('disconnect', handleDisconnect)
      setAccountAddress(newAccounts[0])
    } catch (error) {
      console.log(error)
    }
  }
  
  const signPayTxn = async () => {
    const { connector, chainId } = session
    if (!connector) {
      return
    }
    const suggestedParams = await new algosdk.Algodv2(
      '',
      `https://${chainId}-api.algonode.cloud`,
      ''
    )
      .getTransactionParams()
      .do()
    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      from: accountAddress,
      to: admin,
      amount: 100000,
      note: new Uint8Array(Buffer.from('example note value')),
      suggestedParams
    })
    const txnsToSign = {
      txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString(
        'base64'
      ),
      message: 'This is a payment transaction that sends 0.1 Algos to yourself.'
    }
    await txnProcess([txnsToSign])
  }

  const signOptInTxn = async () => {
    const { connector, chainId } = session
    const assetId = state?.assetId
    if (!connector) {
      return
    } else if (!assetId) {
      return alert('Enter asset id')
    }
    const suggestedParams = await new algosdk.Algodv2(
      '',
      `https://${chainId}-api.algonode.cloud`,
      ''
    )
      .getTransactionParams()
      .do()
    const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: accountAddress,
      to: accountAddress,
      amount: 0,
      assetIndex: +assetId,
      suggestedParams
    })
    const txnsToSign = {
      txn: Buffer.from(algosdk.encodeUnsignedTransaction(optInTxn)).toString(
        'base64'
      ),
      message:
        'This transaction opts you into the given assetid if you have not already opted in.'
    }
    await txnProcess([txnsToSign])
  }

  const txnProcess = async txnsToSign => {
    try {
      const { connector, chainId } = session
      setState({
        ...state,
        pendingRequest: true,
        msg: 'Pending Request! Approve or reject request using your wallet'
      })
      const walletTxns = txnsToSign.map(
        ({ txn, signers, authAddr, message }) => ({
          txn,
          signers, // TODO: put auth addr in signers array
          authAddr,
          message
        })
      )
      // sign transaction
      const requestParams = [walletTxns]
      const request = formatJsonRpcRequest('algo_signTxn', requestParams)
      const result = await connector.sendCustomRequest(request)
      console.log('Raw response:', result)
      setState({ ...state, pendingRequest: true, msg: 'Call Request Approved' })
      const decodedResult = result.map(element =>
        element ? new Uint8Array(Buffer.from(element, 'base64')) : null
      )
      const signedTxnInfo = algosdk.decodeSignedTransaction(decodedResult[0])
      const txID = signedTxnInfo.txn.txID()
      const signedTxns = decodedResult.map(item => item)
      setState({
        ...state,
        result,
        signedTxns,
        pendingRequest: true,
        msg: 'Submitting on network...'
      })
      const confirmedRound = await submitTxnOnNetwork(signedTxns, chainId)
      setState({
        ...state,
        pendingRequest: false,
        label: `Transaction confirmed at round ${confirmedRound}, txid: ${txID}`
      })
    } catch (error) {
      console.log(error.message)
      setState({ ...state, pendingRequest: false, label: `${error.message}` })
    }
  }

  const submitTxnOnNetwork = async (signedTxns, chain) => {
    const client = new algosdk.Algodv2(
      '',
      `https://${chain}-api.algonode.cloud`,
      ''
    )
    const { txId } = await client.sendRawTransaction(signedTxns[0]).do()
    let lastStatus = await client.status().do()
    let lastRound = lastStatus['last-round']
    while (true) {
      const status = await client.pendingTransactionInformation(txId).do()
      if (status['pool-error']) {
        throw new Error(`Transaction Pool Error: ${status['pool-error']}`)
      }
      if (status['confirmed-round']) {
        return status['confirmed-round']
      }
      lastStatus = await client.statusAfterBlock(lastRound + 1).do()
      lastRound = lastStatus['last-round']
    }
  }
  
  const getAccountInfo = async (chain, address) => {
    const client = new algosdk.Algodv2(
      '',
      `https://${chain}-api.algonode.cloud`,
      ''
    )

    const accountInfo = await client
      .accountInformation(address)
      .setIntDecoding(algosdk.IntDecoding.BIGINT)
      .do()
    return { accountInfo }
  }
  
  const init = async () => {
    // Reconnect to the session when the component is mounted
    const accounts = await peraWallet.reconnectSession()
    // Setup the disconnect event listener
    console.log('accounts', accounts)
    // console.log('pw', peraWallet)
    peraWallet?.connector?.on('disconnect', handleDisconnect)
    if (accounts.length) {
      setAccountAddress(accounts[0])
      setSession(peraWallet)
      const { accountInfo } = await getAccountInfo('testnet', accounts[0])
      setState({ ...state, accountInfo })
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    
    <div className='container-fluid'>
      <h6>Pera Wallect Connect</h6>
      <hr></hr>
      {!accountAddress && (
        <div className='form-group mb-3'>
          <div className={`mb-3`}>
            <button
              onClick={handleConnect}
              className='btn btn-sm btn-primary back-color-main height-md rounded-pill px-3'
            >
              <small>Connect to Pera Wallet</small>
            </button>
          </div>
        </div>
      )}
      {accountAddress && isConnectedToPeraWallet && (
        <>
          <div className='form-group mb-3'>
            <label className='form-label small'>
              Account: {accountAddress || ''}
            </label>
          </div>
          <div className='form-group mb-3'>
            <label className='form-label small'>
              Balance: {parseInt(state?.accountInfo?.amount) || ''}
            </label>
          </div>
          <div className={`mb-3`}>
            <button
              onClick={handleDisconnect}
              className='btn btn-sm btn-primary back-color-main height-md rounded-pill px-3'
            >
              <small>Disconnect</small>
            </button>
          </div>
          <hr></hr>
          <div className='form-group mb-3'>
            <div className={`mb-3`}>
              <button
                onClick={signPayTxn}
                className='btn btn-sm btn-primary back-color-main height-md px-3'
              >
                <small>Sign pay txn</small>
              </button>
            </div>
          </div>
          <div className='opt-in'>
            {/* <div className='form-group mb-3 opt-in-text'>
              <label className='form-label small'>
                Enter Asset Id for Opt In:
                <span className='text-danger'>*</span>
              </label>
              <input
                type='number'
                className='form-control height-md'
                onChange={e => setState({ ...state, assetId: e.target.value })}
              ></input>
            </div> */}
            {/* <div className='form-group mb-3 opt-in-btn'>
              <div className={`mb-3`}>
                <button
                  onClick={signOptInTxn}
                  className='btn btn-sm btn-primary back-color-main height-md rounded-pill px-3'
                >
                  <small>2. Sign Opt In Txn</small>
                </button>
              </div>
            </div> */}
          </div>

          {state?.pendingRequest && <WebbLoader />}
          {state?.label || ''}
        </>
      )}
    </div>
  )
}
