// nft-sdk
import { NFTStorage, File, Blob } from 'nft.storage'
import { v4 as uuidv4 } from 'uuid';

const client = new NFTStorage({ token: process.env.REACT_APP_NFT_STORE_APIK })

export const MetadataMediaCreate = async (item) => {

  const mediax = new File([ item.file ], 'media.png', { type: 'image/png' })
  console.log (item)

  const result = await client.store({
    name: item.name,
    description: item.memo,
    image: mediax //item.file
  })
  var datx
  if (result) datx = {
    name: result.data.name,
    description: result.data.description,
    image: `https://nftstorage.link/ipfs/${result.data.image.href.split('ipfs://')[1]}`,
    link: `https://nftstorage.link/ipfs/${result.ipnft}/metadata.json`
  }
  console.log(datx)
  return datx

}

export const MediaLinkCreate = async (item) => {

  const mediax = new File([ item.media ], `${uuidv4().split('-').join('')}.${item.media.type.split('/')[1]}`, { type: item.media.type })
  // console.log (item)

  const result = await client.storeBlob(mediax)
  console.log({link: `https://nftstorage.link/ipfs/${result}`})
  return ({link: `https://nftstorage.link/ipfs/${result}`, mime: item.media.type })

}


export const MetadataLinkCreate = async (item) => {

  const tokendata = {
    "name": item.name,
    "description": item.memo,
    "image": item.media.link,
    "properties" : {},
    "attributes": []
  }
  // console.log ('meta: ', tokendata)

  // var blob = new Blob([JSON.stringify(tokendata)], {type: "application/json;charset=utf-8"});
  // saveAs(blob, `route-${Date.now()}.json`);

  const metablob = new File([ JSON.stringify(tokendata) ], `${uuidv4().split('-').join('')}.json`, { type: item.media.type })
  const result = await client.storeBlob(metablob)

  console.log({link: `https://nftstorage.link/ipfs/${result}`})
  return ({link: `https://nftstorage.link/ipfs/${result}`})

}

