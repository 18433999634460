// init
import axios from "axios";
const base = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/route-coins-aivnt/endpoint'

// -----------------


export const CoinRatesList = async (item) => {
  
  const basx = base + '/coins/rates';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_SCRT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return result.data
  } catch (error) {
    console.log (error);
    return error
  }
  
}


export const CoinPriceList = async (item) => {
  
  const basx = base + 'https://api.coingecko.com/api/v3/simple/price?ids=celo%2Cbinancecoin%2Cmatic-network%2Calgorand&vs_currencies=usd';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": ''
  }
  // const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR}

  var result;
  try {
    result = await axios.get(basx);
    console.log (result)
    return result.data
  } catch (error) {
    console.log (error);
    return error
  }
  
}
